import React, {lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Vendor CSS Files
// import './assets/vendor/aos/aos.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
// import './assets/vendor/swiper/swiper-bundle.min.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
//Template Main CSS File
import './assets/css/style-8.css';
// import './assets/css/social.css';
import './assets/css/placeholder-loading.min.css';
import logo from './assets/img/loader-logo.png';
// import LayoutMain from "./layout/main";

const loading = (
  <div className="preloader flex-column justify-content-center align-items-center">
    <img className="animation__shake" src={logo} alt="Mastery Inside" height="200" width="200" />
  </div>
)

const LayoutMain = lazy(() => import('./layout/main'));
const LayoutSubPage = lazy(() => import("./layout/sub-page"));
const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const ServiceList = lazy(() => import("./pages/services/service"));
const ServiceDetail = lazy(() => import("./pages/services/service-details"));
const OurTeam = lazy(() => import("./pages/team/team"));
const TeamDetail = lazy(() => import("./pages/team/team-details"));
const ClientPage = lazy(() => import("./pages/client"));
const ContactPage = lazy(() => import("./pages/contact"));
const BlogList = lazy(() => import("./pages/blog/blog"));
const BlogDetail = lazy(() => import("./pages/blog/blog-details"));
const DoorwayPage = lazy(() => import("./pages/doorway/doorway"));
const DoorwayDetail = lazy(() => import("./pages/doorway/doorway-details"));
const FounderDetail = lazy(() => import("./pages/team/founder"));
const NotFoundPage = lazy(() => import("./pages/error/404"));

function App() {
  // useEffect(() => {
  //   const backtotop = document.getElementById('scrollToTopp')
  //   // let backtotop = document.getElementById('test');
  // console.log('backtotop', backtotop, 'backtotopbacktotopbacktotopbacktotop')
  // if (backtotop) {
  //   const toggleBacktotop = () => {
  //     if (window.scrollY > 100) {
  //       backtotop.classList.add('active')
  //     } else {
  //       backtotop.classList.remove('active')
  //     }
  //   }
  //   window.addEventListener('load', toggleBacktotop)
  //   onscroll(document, toggleBacktotop)
  // }
  // }, []);

  return (
    <Suspense fallback={loading}>
    <BrowserRouter>
      <Routes>
          <Route element={<LayoutMain title='Success Begins Here' />}>
            <Route path="/" element={<Home/>} />
          </Route>
        <Route path="/about-us" element={<LayoutSubPage title='About'><About /></LayoutSubPage>}/>
        <Route path="/services-individual" element={<LayoutSubPage title='Individual Services'><ServiceList type="individual"/></LayoutSubPage>}/>
        <Route path="/services-corporate" element={<LayoutSubPage title='Corporate Services'><ServiceList type="corporate"/></LayoutSubPage>}/>
        <Route path="/our-team" element={<LayoutSubPage title='Our Team'><OurTeam /></LayoutSubPage>}/>
        <Route path="/clients" element={<LayoutSubPage title='Our Clients'><ClientPage /></LayoutSubPage>}/>
        <Route path="/contact-us" element={<LayoutSubPage title='Contact Us'><ContactPage /></LayoutSubPage>}/>
        <Route path="/blog" element={<LayoutSubPage title='Blog'><BlogList /></LayoutSubPage>}/>
        <Route path="/blog-detail/:slug" element={<LayoutSubPage title='Blog'><BlogDetail /></LayoutSubPage>}/>
        <Route path="/mastery-doorways" element={<LayoutSubPage title='Doorways To Mastery'><DoorwayPage /></LayoutSubPage>} />
        <Route path="/mastery-doorways/:slug" element={<DoorwayDetail />}/>
        <Route path="/services/service-details/:slug" element={<ServiceDetail />}/>
        <Route path="/founder" element={<LayoutSubPage title='Major Deepak Iyer'><FounderDetail /></LayoutSubPage>} />
        <Route path="/our-team/:slug" element={<TeamDetail />}/>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
    </Suspense>
  );
}

export default App;
